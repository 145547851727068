import React from 'react';
import logo from './logo.svg';
import './App.scss';

function App() {
  return <main>
    <section>
    <nav className="navbar pt-4 pb-4 fixed-top">
  <div className="container">
    <a className="navbar-brand">Navbar</a>
    <nav className="nav">
  <a className="nav-link active" aria-current="page" href="#"> Início</a>
  <a className="nav-link" href="#">Projetos</a>
  <a className="nav-link" href="#">Sobre</a>
  <a className="nav-link">Experiências</a>
</nav>
  </div>
</nav>
      <div className="jumbotron d-flex align-items-center">
        <div className="container">
          <h1>
          Olá👋, <br />
          Sou <span className='bg-g-text'>Daniel Kitanaxi</span>, desenvolvedor front-end especializado em criar interfaces modernas e funcionais para oferecer a melhor experiência aos usuários.
          </h1>
        </div>
      </div>

      {/**
       * <div className="protifolio pt-5 pb-5 mt-5 mb-5">
        <div className="container">
        <h3>Obras Selecionadas</h3>
        </div>
      </div>
       */}
      
    </section>
  </main>
}

export default App;
